<template>
    <div>
      <div class="page-wrapper">
        <div class="page-content-wrapper">
          <div class="shadow-btm">
            <div class="row col-lg-6 col-sm-6 my-auto">
                <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">Manage Reply</h4>
            </div>
          </div>
          <div
            class="page-content"
            v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage"
          >
            <div class="card-body py-0 px-0">
              <ul class="nav nav-pills m-3">
                <li class="nav-item cursor-pointer">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    v-on:click="setStatus('ACTIVE')"
                    :class="{ active: currentTab == 'ACTIVE' }"
                  >
                    <span>Active</span></a
                  >
                </li>
                <!-- <li class="nav-item cursor-pointer">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    v-on:click="setStatus('DELETED')"
                    :class="{ active: currentTab == 'DELETED' }"
                  >
                    <span>Deleted</span></a
                  >
                </li> -->
              </ul>
  
              <div class="row">
                <div class="col-lg-6 col-sm-6 form-group row">
                  <div class="has-search ml-3">
                    <i
                      class="fa fa-search"
                      style="position: absolute; margin: 10px"
                      aria-hidden="true"
                    ></i>
                    <input
                      type="text"
                      class="form-control"
                      v-on:keyup.enter="getList(0)"
                      placeholder="Search"
                      v-model="searchObj.keyword"
                    />
                  </div>
                 
                  <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getList(0)"
                    >Search</a
                  >
                  <a
                    class="fill-btn btn-style cursor-pointer"
                    v-on:click="resetFilter"
                    v-show="searchObj.keyword"
                    >Reset</a
                  >
                </div>
                <div class="col-md-6 text-right" v-if="currentTab == 'ACTIVE'">
                  <!-- <a
                    v-on:click="exportCustomer()"
                    class="fill-btn btn-style cursor-pointer ml-3"
                    data-toggle="modal"
                    >Export</a
                  >
  
                  <a
                    v-show="accessObject?.is_add == 1"
                    class="fill-btn cursor-pointer btn-style"
                    v-on:click="$router.push('/add-customer')"
                    >+ Add</a
                  > -->
  
                  <!-- <a
                    v-if="currentTab == 'ACTIVE'"
                    class="fill-btn cursor-pointer btn-style"
                    style="padding: 11px; margin-right: 16px"
                    v-on:click="deleteAllPopup"
                    >Delete
                  </a> -->
                </div>
              </div>
              <div class="card border mb-0 radius-15">
                <div class="card-body p-0 pb-4">
                  <div class="table-responsive">
                    <div class="dataTables_wrapper dt-bootstrap4">
                      <table
                        class="table table-striped table-bordered dataTable"
                        style="width: 100%"
                        role="grid"
                        aria-describedby="example_info"
                      >
                        <thead>
                          <tr role="row">
                            <th>Sr. No.</th>
                           
                            <!-- <th
                              class="sorting"
                              id="id-name"
                              v-on:click="sorting('name', 'id-title')"
                            >
                              Post Name
                            </th> -->
                            <th
                              class="sorting"
                              id="id-catgeory_id"
                              v-on:click="sorting('catgeory_id', 'id-catgeory_id')"
                            >
                              Comment
                            </th>
                            <th
                            >
                              Reply By
                            </th>
                            <th
                            >
                              Reply At
                            </th>
                            <th
                            >
                              Total Like 
                            </th>
                            <!-- <th
                            >
                              Total Reply
                            </th> -->
                            <!-- <th
                              v-if="currentTab == 'ACTIVE'"
                            >
                              <input
                                type="checkbox"
                                id="deleteAll"
                                v-on:click="selectAllData($event)"
                                true-value="1"
                                false-value="0"
                                class="mr-2"
                                v-model="is_select"
                                :class="is_select == '0' ? 'a' : 'b'"
                              />
                              Select
                            </th> -->
                            <th
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody v-show="!loader">
                          <tr v-for="(data, index) in list" :key="index">
                            <td style="text-align: left">
                              {{ ($refs.listPagination.currentPage - 1) * 10 + index + 1 }}
                            </td>
                            <!-- <td style="text-align: left">{{ data?.post?.title }}</td> -->
                            <td style="text-align: left">
                              {{ data?.comment }}
                            </td>
                            <td style="text-align: left">
                              {{ data?.user?.name }}
                            </td>
                            {{ $helperService.getFormatedDateTime(data?.created_at) }}
                            <td style="text-align: left">
                              {{ data?.like_count }}
                            </td>
                            <!-- <td style="text-align: left">
                              {{ data?.reply_count }}
                            </td>
                            <td
                              v-if="currentTab == 'ACTIVE'"
                            >
                              <input
                                type="checkbox"
                                :id="'delete_' + data.id"
                                v-on:click="deleteData($event, data)"
                                v-model="data.is_select"
                                true-value="1"
                                false-value="0"
                                class="mr-2"
                                :class="data.is_select == '1' ? 'a' : 'b'"
                              />
                            </td> -->
                            <td
                            >
                              
                              <a
                                v-if="currentTab == 'ACTIVE'"
                                class="fill-btn btn-style cursor-pointer"
                                v-on:click="deleteSinglePopup(data?.id)"
                                >Delete</a
                              >
                              <a
                                v-if="currentTab == 'DELETED'"
                                class="fill-btn btn-style cursor-pointer"
                                v-on:click="showConfirmation('ACTIVE',data?.id)"
                                >Restore</a
                              >
                              <a
                                v-if="currentTab == 'DELETED'"
                                class="fill-btn btn-style cursor-pointer"
                                v-on:click="showConfirmation('DELETED',data?.id)"
                                >Permanent Delete</a
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row mx-0 mb-4">
                    <div class="col-lg-12 mt-5 text-center">
                      <div class="page-bottom-pagination text-right">
                        <Pagination
                          @page-change="pageChange"
                          @items-per-page-change="itemsPerPageChange"
                          ref="listPagination"
                        />
                      </div>
                    </div>
                  </div>
                  <ErrorComponent @retry="getList(0)" ref="errorComponent" />
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <DeleteModal @remove="deleteAllRecord" ref="deleteModal" />
      <DeleteModal @remove="deleteSingleRecord" ref="deleteSingleModal" />
      <TextModal ref="confirmation">
        <div class="modal-header">
          <h5 class="modal-title">Confirmation</h5>
          <button type="button" class="close" v-on:click="closeConfirmation()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 col-sm-12 mb-3">
                Are you sure you want to <span v-if="action_status == 'ACTIVE'">restore post?</span> <span v-else>permanent delete post?</span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="fill-btn" @click="updateStatusApiCall()" id="confirmation-btn">Yes</button>
            <button
              type="button"
              class="fil-btn"
              id="cancel-button"
              v-on:click="closeConfirmation()"
            >
              Cancel
            </button>
          </div>
      </TextModal>
    </div>
  </template>
  <script>
  import ErrorComponent from "@/components/ErrorComponent";
  import DeleteModal from "@/components/DeleteModal";
  import Pagination from "@/components/Pagination";
  import TextModal from "@/components/TextModal";
  import Report from "../../Report";
  export default {
    name: "Post",
    components: {
      ErrorComponent,
      DeleteModal,
      Pagination,
      TextModal,
    },
    data() {
      return {
        accessObject: this.$helperService.getAccessLevel("Manage Comments"),
        loader: true,
        isShowAccessDeniedMessage: false,
        id: this.$route.params.id,
        docFile: undefined,
        offset: -1,
        list: [],
        itemsPerPage: 10,
        searchObj: {
          keyword: "",
          status: "ACTIVE",
          catgeory_id:null
        },
        catgeories: [],
        deleteList: [],
        is_select: "0",
        currentTab: "ACTIVE",
        delete_id:null,
        action_status:null,
        confirmation_id:null
      };
    },
    mounted() {
     
      this.getCatgeory();
      this.getList(0);
    },
    methods: {
      pageChange(offset) {
        this.getList(offset - 1);
      },
      itemsPerPageChange() {
        this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
        this.getList(0);
      },
      setStatus(status) {
        this.searchObj.status = status;
        this.currentTab = status;
        this.getList(0);
      },
      getCatgeory() {
          this.$api
            .getAPI({
              _action: "/top-menu/category/listv2",
              _body: { lang: this.lang_code },
            })
            .then((res) => {
              this.catgeories = res;
            })
            .catch(() => {});
      },
      getList(page, orderBy, sortBy) {
        this.$refs.errorComponent.updateLoader(true);
        this.list = [];
        this.searchObj.offset = page;
        this.searchObj.limit = this.itemsPerPage;
        if (orderBy) {
          this.searchObj.order_by = orderBy;
        }
        if (sortBy) {
          this.searchObj.sort_by = sortBy;
        }
        this.$api
          .getAPI({
            _action: "/admin/post/reply/list/" + this.id,
            _body: this.searchObj,
          })
          .then((res) => {
            this.loader = false;
            this.list = res.list;
            this.offset = res.next_offset;
            if (page == 0) {
              if (this.$refs.listPagination) {
                if (res.count != null && res.count > 0) {
                  this.$refs.listPagination.setTotalCount(res.count);
                } else {
                  this.$refs.listPagination.setTotalCount(0);
                }
              }
            }
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res, "No Comments available");
              this.$refs.errorComponent.updateLoader(false);
            }
            for (var i = 0; i < this.list.length; i++) {
              this.list[i].is_select = "0";
            }
          })
          .catch(() => {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateLoader(false);
            }
          });
      },
      deleteAllPopup() {
        this.$refs.deleteModal.showModal(
          "Delete Admins",
          "Are you sure you want to delete " + this.deleteList.length,
          +" admins"
        );
      },
      deleteAllRecord() {
        this.$api
          .postAPI({
            _action: "/delete/post-comment-all'",
            _body: { list: this.deleteList },
          })
          .then((res) => {
            if (res) {
              this.deleteList = [];
              this.is_select = "0";
  
              this.$refs.deleteModal.closeModal();
              this.getList();
              this.$notify({
                type: "success",
                text: res.message,
              });
            }
          })
          .catch(() => {
            this.$refs.deleteModal.removeLoader();
          });
      },
      deleteSinglePopup(id) {
        this.delete_id = id;
        this.$refs.deleteSingleModal.showModal(
          "Delete Admins",
          "Are you sure you want to delete",
        );
      },
      deleteSingleRecord() {
        this.$api
          .deleteAPI({
            _action: "post/comment/reply/" + this.delete_id,
          })
          .then((res) => {
            if (res) {
              this.$refs.deleteSingleModal.closeModal();
              this.delete_id = null;
              this.getList();
              this.$notify({
                type: "success",
                text: res.message,
              });
            }
          })
          .catch(() => {
            this.$refs.deleteModal.removeLoader();
          });
      },
  
      deleteData(event, data) {
        if (event.target.checked) {
          this.deleteList.push(data.id);
        } else {
          for (var i = 0; i < this.deleteList.length; i++) {
            if (this.deleteList[i] == data.id) {
              this.deleteList.splice(i, 1);
            }
          }
        }
  
        setTimeout(() => {
          this.is_select = "1";
          for (var j = 0; j < this.list.length; j++) {
            if (this.list[j].is_select == "0") {
              this.is_select = "0";
              break;
            }
          }
        }, 1000);
      },
      selectAllData(event) {
        if (event.target.checked) {
          for (var i = 0; i < this.list.length; i++) {
            this.deleteList.push(this.list[i].id);
            this.list[i].is_select = "1";
          }
        } else {
          this.deleteList = [];
          for (var j = 0; j < this.list.length; j++) {
            this.list[j].is_select = "0";
          }
        }
      },
      sorting(sortBy, id) {
        var obj = this.$helperService.sorting(sortBy, id);
        this.getList(0, obj.order_by, obj.sort_by);
      },
      resetFilter() {
        this.searchObj.keyword = "";
        this.searchObj.catgeory_id = null;
        this.getList(0);
      },
      showPracticePopup() {
        this.$refs.textModal.showModal();
      },
      showDeletePopup(data) {
        this.$refs.deleteModal.showModal(
          "Delete Customer",
          "Are you sure you want to delete " + data.name,
          data
        );
      },
      exportCustomer() {
        var url = "/customer/export";
        url += "?keyword=" + this.searchObj.keyword;
        window.open(Report.REPORT_URL + "/api" + url);
      },
  
      deleteRecord(dataObj) {
        var detail = new Proxy(dataObj, {});
        this.$api
          .deleteAPI({
            _action: "/user/" + detail.uuid,
          })
          .then((res) => {
            if (res) {
              this.$refs.deleteModal.closeModal();
              this.getList();
              this.$notify({
                type: "success",
                text: res.message,
              });
            }
          })
          .catch(() => {
            this.$refs.deleteModal.removeLoader();
          });
      },
      uploadDocument() {
        if (this.docFile) {
          this.$api
            .uploadImageAPI({
              _action: "/import/data",
              _key: "file",
              _file: this.docFile,
              _buttonId: "document-button",
            })
            .then(() => {
              this.$refs.textModal.closeModal();
            });
        } else {
          this.$refs.textModal.closeModal();
        }
      },
      showConfirmation(status,confirmation_id) {
        this.action_status = status;
        this.confirmation_id = confirmation_id;
        this.details = {};
        this.$refs.confirmation.showModal();
      },
      closeConfirmation(){
        this.$refs.confirmation.closeModal();
        this.action_status = null;
        this.confirmation_id = null;
      },
      updateStatusApiCall() {
        this.$api
          .postAPI({
            _action: "/update-status-post-comment" + this.confirmation_id,
            _body:{status:this.action_status},
            _buttonId:"confirmation-bnt"
          })
          .then((res) => {
            if (res) {
              this.closeConfirmation();
              this.getList();
              this.$notify({
                type: "success",
                text: res.message,
              });
            }
          })
          .catch(() => {
            this.$refs.deleteModal.removeLoader();
          });
      },
    },
  };
  </script>
  