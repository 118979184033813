<template>
    <div>
        <div class="page-wrapper">
            <div class="page-content-wrapper">
                <div class="shadow-btm">
                    <div class="row col-lg-6 col-sm-6 my-auto">
                        <h4 class="mb-0 lg-bld">Manage Reviews</h4>
                    </div>
                </div>
                <div class="page-content" v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage">
                    <div class="card-body py-0 px-0">
                        <ul class="nav nav-pills m-3">
                            <li class="nav-item cursor-pointer">
                                <a class="nav-link" data-toggle="tab" v-on:click="setStatus('ACTIVE')"
                                    :class="{ active: currentTab == 'ACTIVE' }">
                                    <span>Active</span></a>
                            </li>
                            <li class="nav-item cursor-pointer">
                                <a class="nav-link" data-toggle="tab" v-on:click="setStatus('DELETED')"
                                    :class="{ active: currentTab == 'DELETED' }">
                                    <span>Deleted</span></a>
                            </li>
                        </ul>

                        <div class="row">
                            <div class="col-lg-6 col-sm-6 form-group row">
                                <div class="has-search ml-3">
                                    <i class="fa fa-search" style="position: absolute; margin: 10px"
                                        aria-hidden="true"></i>
                                    <input type="text" class="form-control" v-on:keyup.enter="getList(0)"
                                        placeholder="Search" v-model="searchObj.keyword" />
                                </div>
                                <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getList(0)">Search</a>
                                <a class="fill-btn btn-style cursor-pointer" v-on:click="resetFilter"
                                    v-show="searchObj.keyword">Reset</a>
                            </div>
                            <div class="col-md-6 text-right" v-if="currentTab == 'ACTIVE'">
                                <!-- <a
                    v-on:click="exportCustomer()"
                    class="fill-btn btn-style cursor-pointer ml-3"
                    data-toggle="modal"
                    >Export</a
                  >
  
                  <a
                    v-show="accessObject?.is_add == 1"
                    class="fill-btn cursor-pointer btn-style"
                    v-on:click="$router.push('/add-customer')"
                    >+ Add</a
                  > -->

                                <!-- <a v-show="accessObject?.is_delete == 1" v-if="currentTab == 'ACTIVE'"
                                    class="fill-btn cursor-pointer btn-style" style="padding: 11px; margin-right: 16px"
                                    v-on:click="deleteAllPopup">Delete
                                </a> -->
                            </div>
                        </div>
                        <div class="card border mb-0 radius-15">
                            <div class="card-body p-0 pb-4">
                                <div class="table-responsive">
                                    <div class="dataTables_wrapper dt-bootstrap4">
                                        <table class="table table-striped table-bordered dataTable" style="width: 100%"
                                            role="grid" aria-describedby="example_info">
                                            <thead>
                                                <tr role="row">

                                                    <th>Sr. No.</th>
                                                    <th class="sorting" id="id-name"
                                                        v-on:click="sorting('name', 'id-name')">
                                                        <div style="width: 150px">Customer Name</div>

                                                    </th>
                                                    <th class="sorting" id="id-barcode"
                                                        v-on:click="sorting('barcode', 'id-barcode')">
                                                        EAN
                                                    </th>
                                                    <th class="sorting" id="id-product_name"
                                                        v-on:click="sorting('product_name', 'id-product_name')">
                                                        Product Name
                                                    </th>
                                                    <th class="sorting" id="id-created_date"
                                                        v-on:click="sorting('ratings.created_at', 'id-created_date')">
                                                        <div style="width: 150px">Created At</div>
                                                    </th>
                                                    <th class="sorting" id="id-created_date"
                                                        v-on:click="sorting('ratings.created_at', 'id-created_date')">
                                                        <div style="width: 150px">Total Reply</div>
                                                    </th>
                                                    <th class="sorting" id="id-rating"
                                                        v-on:click="sorting('rating', 'id-rating')">
                                                        Ratings
                                                    </th>
                                                    <!-- <th v-show="accessObject?.is_delete == 1"
                                                        v-if="currentTab == 'ACTIVE'">
                                                        <input type="checkbox" id="deleteAll"
                                                            v-show="accessObject?.is_delete == 1"
                                                            v-on:click="selectAllData($event)" true-value="1"
                                                            false-value="0" class="mr-2" v-model="is_select"
                                                            :class="is_select == '0' ? 'a' : 'b'" />
                                                        Select
                                                    </th> -->
                                                    <th>
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-show="!loader">
                                                <tr v-for="(data, index) in list" :key="index">
                                                    <td style="text-align: left;">
                                                        {{
                                                            ($refs.listPagination.currentPage - 1) * 10 +
                                                            index +
                                                            1
                                                        }}
                                                    </td>
                                                    <td style="text-align: left;">{{ data.customer.name }}</td>
                                                    <td style="text-align: left;">{{ data.product.barcode }}</td>
                                                    <td style="text-align: left;">{{ data.product.name }}</td>
                                                    <td style="text-align: left;">{{
                                                        $helperService.getFormatedDate(data.created_at) }}</td>
                                                    <td style="text-align: left;">{{
                                                        data?.reply_count }}</td>
                                                    <td>
                                                        <div v-html="generateStars(data?.rating)"></div>
                                                    </td>
                                                    <!-- <td v-show="accessObject?.is_delete == 1"
                                                        v-if="currentTab == 'ACTIVE'">
                                                        <input type="checkbox" :id="'delete_' + data.id"
                                                            v-show="accessObject?.is_delete == 1"
                                                            v-on:click="deleteData($event, data)"
                                                            v-model="data.is_select" true-value="1" false-value="0"
                                                            class="mr-2" :class="data.is_select == '1' ? 'a' : 'b'" />
                                                    </td> -->
                                                    <td>
                                                        <a class="fill-btn btn-style cursor-pointer"
                                                            v-show="accessObject?.is_view == 1"
                                                            v-on:click="showViewReviewPopup(data)">View</a>
                                                        <a class="fill-btn btn-style cursor-pointer"
                                                            v-show="accessObject?.is_view == 1"
                                                            v-on:click="$router.push('/reply-review/' + data.id)">View
                                                            Reply</a>

                                                        <a v-if="currentTab == 'ACTIVE'"
                                                            class="fill-btn btn-style cursor-pointer"
                                                            v-on:click="deleteSinglePopup(data)"
                                                            v-show="accessObject?.is_delete == 1">Delete</a>
                                                        <a v-if="currentTab == 'DELETED'"
                                                            class="fill-btn btn-style cursor-pointer"
                                                            v-on:click="showConfirmation('ACTIVE', data?.id)"
                                                            v-show="accessObject?.is_delete == 1">Restore</a>
                                                        <a v-if="currentTab == 'DELETED'"
                                                            class="fill-btn btn-style cursor-pointer"
                                                            v-on:click="showConfirmation('DELETED', data?.id)"
                                                            v-show="accessObject?.is_delete == 1">Permanent Delete</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row mx-0 mb-4">
                                    <div class="col-lg-12 mt-5 text-center">
                                        <div class="page-bottom-pagination text-right">
                                            <Pagination @page-change="pageChange"
                                                @items-per-page-change="itemsPerPageChange" ref="listPagination" />
                                        </div>
                                    </div>
                                </div>
                                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center" style="margin-top: 20%"
                    v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage">
                    <h3>You do not have access to view posts</h3>
                </div>
            </div>
        </div>
        <DeleteModal @remove="deleteAllRecord" ref="deleteModal" />
        <DeleteModal @remove="deleteSingleRecord" ref="deleteSingleModal" />
        <TextModal ref="textModal">
            <div class="modal-header pt-0">
                <h5 class="modal-title">View Review</h5>
                <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <Form ref="reviewForm">
                <div class="modal-body">
                    <div class="col-lg-12 col-sm-12">
                        <label>Comment</label>
                        <div class="" id="message-div" style="overflow-x: hidden; scrollbar-width: thin; ">
                            {{ reviewDetail.comment }}
                            <div v-html="generateStars(reviewDetail?.rating)"></div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12"
                        v-if="reviewDetail?.image !== null || reviewDetail?.video !== null">
                        <img :src="reviewDetail?.image !== null ? reviewDetail?.image : reviewDetail?.video"
                            class="img-fluid">
                    </div>
                </div>
                <div class="modal-footer pb-0">
                    <button type="button" class="fil-btn" id="cancel-button" v-on:click="$refs.textModal.closeModal"
                        style="width: 20%; border-radius: 5px">
                        Close
                    </button>
                </div>
            </Form>
        </TextModal>
        <TextModal ref="confirmation">
            <div class="modal-header">
                <h5 class="modal-title">Confirmation</h5>
                <button type="button" class="close" v-on:click="closeConfirmation()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12 col-sm-12 mb-3">
                        Are you sure you want to <span v-if="action_status == 'ACTIVE'">restore review?</span> <span
                            v-else>permanent delete review?</span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="fill-btn" @click="updateStatusApiCall()" id="confirmation-btn-new">Yes</button>
                <button type="button" class="fil-btn" id="cancel-button" v-on:click="closeConfirmation()">
                    Cancel
                </button>
            </div>
        </TextModal>
    </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
import TextModal from "@/components/TextModal";
import Report from "../../Report";
export default {
    name: "Post",
    components: {
        ErrorComponent,
        DeleteModal,
        Pagination,
        TextModal,
    },
    data() {
        return {
            accessObject: this.$helperService.getAccessLevel("Manage Reviews"),
            loader: true,
            isShowAccessDeniedMessage: false,
            docFile: undefined,
            offset: -1,
            list: [],
            itemsPerPage: 10,
            searchObj: {
                keyword: "",
                status: "ACTIVE",
                catgeory_id: null
            },
            deleteList: [],
            is_select: "0",
            currentTab: "ACTIVE",
            delete_id: null,
            action_status: null,
            confirmation_id: null,
            reviewDetail: {}
        };
    },
    mounted() {
        if (!this.accessObject) {
            this.isShowAccessDeniedMessage = true;
            this.$router.push("/access-denied");
            return;
        }
        this.getList(0);
    },
    methods: {
        generateStars(rating) {
            let stars = '';
            for (let i = 0; i < 5; i++) {
                if (i < rating) {
                    stars += '<i class="fa fa-star" style="color: gold;"></i>';
                } else {
                    stars += '<i class="fa fa-star" style="color: lightgray;"></i>';
                }
            }
            return stars;
        },
        pageChange(offset) {
            this.getList(offset - 1);
        },
        itemsPerPageChange() {
            this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
            this.getList(0);
        },
        setStatus(status) {
            this.searchObj.status = status;
            this.currentTab = status;
            this.getList(0);
        },

        getList(page, orderBy, sortBy) {
            this.$refs.errorComponent.updateLoader(true);
            this.list = [];
            this.searchObj.offset = page;
            this.searchObj.limit = this.itemsPerPage;
            if (orderBy) {
                this.searchObj.order_by = orderBy;
            }
            if (sortBy) {
                this.searchObj.sort_by = sortBy;
            }
            this.$api
                .getAPI({
                    _action: "/all/review/list/pagination",
                    _body: this.searchObj,
                })
                .then((res) => {
                    this.loader = false;
                    this.list = res.list;
                    this.offset = res.next_offset;
                    if (page == 0) {
                        if (this.$refs.listPagination) {
                            if (res.count != null && res.count > 0) {
                                this.$refs.listPagination.setTotalCount(res.count);
                            } else {
                                this.$refs.listPagination.setTotalCount(0);
                            }
                        }
                    }
                    if (this.$refs.errorComponent) {
                        this.$refs.errorComponent.setData(res, "No Review available");
                        this.$refs.errorComponent.updateLoader(false);
                    }
                    for (var i = 0; i < this.list.length; i++) {
                        this.list[i].is_select = "0";
                    }
                })
                .catch(() => {
                    this.loader = false;
                    if (this.$refs.errorComponent) {
                        this.$refs.errorComponent.setData(0, undefined, "");
                        this.$refs.errorComponent.updateLoader(false);
                    }
                });
        },
        deleteAllPopup() {
            this.$refs.deleteModal.showModal(
                "Delete Admins",
                "Are you sure you want to delete " + this.deleteList.length,
                +" admins"
            );
        },
        deleteAllRecord() {
            this.$api
                .postAPI({
                    _action: "/delete/post-all",
                    _body: { list: this.deleteList },
                })
                .then((res) => {
                    if (res) {
                        this.deleteList = [];
                        this.is_select = "0";

                        this.$refs.deleteModal.closeModal();
                        this.getList();
                        this.$notify({
                            type: "success",
                            text: res.message,
                        });
                    }
                })
                .catch(() => {
                    this.$refs.deleteModal.removeLoader();
                });
        },
        // deleteSinglePopup(id) {
        //     this.delete_id = id;
        //     this.$refs.deleteSingleModal.showModal(
        //         "Delete Admins",
        //         "Are you sure you want to delete",
        //     );
        // },
        deleteSinglePopup(data) {
            this.delete_id = data.id;
            this.$refs.deleteSingleModal.showModal(
                "Delete review",
                "Are you sure you want to delete",
            );
        },
        deleteSingleRecord() {
            this.$api
                .deleteAPI({
                    _action: "/review/" + this.delete_id,
                })
                .then((res) => {
                    if (res) {
                        this.$refs.deleteSingleModal.closeModal();
                        this.delete_id = null;
                        this.getList();
                        this.$notify({
                            type: "success",
                            text: res.message,
                        });
                    }
                })
                .catch(() => {
                    this.$refs.deleteModal.removeLoader();
                });
        },

        deleteData(event, data) {
            if (event.target.checked) {
                this.deleteList.push(data.id);
            } else {
                for (var i = 0; i < this.deleteList.length; i++) {
                    if (this.deleteList[i] == data.id) {
                        this.deleteList.splice(i, 1);
                    }
                }
            }

            setTimeout(() => {
                this.is_select = "1";
                for (var j = 0; j < this.list.length; j++) {
                    if (this.list[j].is_select == "0") {
                        this.is_select = "0";
                        break;
                    }
                }
            }, 1000);
        },
        selectAllData(event) {
            if (event.target.checked) {
                for (var i = 0; i < this.list.length; i++) {
                    this.deleteList.push(this.list[i].id);
                    this.list[i].is_select = "1";
                }
            } else {
                this.deleteList = [];
                for (var j = 0; j < this.list.length; j++) {
                    this.list[j].is_select = "0";
                }
            }
        },
        sorting(sortBy, id) {
            var obj = this.$helperService.sorting(sortBy, id);
            this.getList(0, obj.order_by, obj.sort_by);
        },
        resetFilter() {
            this.searchObj.keyword = "";
            this.searchObj.catgeory_id = null;
            this.getList(0);
        },
        showPracticePopup() {
            this.$refs.textModal.showModal();
        },


        showViewReviewPopup(data) {
            this.reviewDetail = data;
            console.log("this.reviewDetail", this.reviewDetail)
            this.$refs.textModal.showModal();
            // this.$refs.reviewForm.resetForm();
        },

        uploadDocument() {
            if (this.docFile) {
                this.$api
                    .uploadImageAPI({
                        _action: "/import/data",
                        _key: "file",
                        _file: this.docFile,
                        _buttonId: "document-button",
                    })
                    .then(() => {
                        this.$refs.textModal.closeModal();
                    });
            } else {
                this.$refs.textModal.closeModal();
            }
        },
        showConfirmation(status, confirmation_id) {
            this.action_status = status;
            this.confirmation_id = confirmation_id;
            this.details = {};
            this.$refs.confirmation.showModal();
        },
        closeConfirmation() {
            this.$refs.confirmation.closeModal();
            this.action_status = null;
            this.confirmation_id = null;
        },
        updateStatusApiCall() {
            this.$api
                .postAPI({
                    _action: "/update-review/" + this.confirmation_id,
                    _body: { status: this.action_status },
                    _buttonId: "confirmation-btn-new"
                })
                .then((res) => {
                    if (res) {
                        this.closeConfirmation();
                        this.getList();
                        this.$notify({
                            type: "success",
                            text: res.message,
                        });
                    }
                })
                .catch(() => {
                    this.$refs.deleteModal.removeLoader();
                });
        },
    },
};
</script>