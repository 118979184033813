<template>
  <div>
    <div class="sidebar-wrapper" style="overflow-y: scroll">
      <div class="sidebar-header">
        <div class="">
          <img src="/images/beuty-logo.svg" width="220" />
        </div>
      </div>
      <ul class="metismenu" id="menu">
        <li v-show="$helperService.getLeftMenuAccess('Dashboard')">
          <a
            id="dashboard"
            :class="{ active: pageName == 'dashboard' }"
            style="cursor: pointer"
            v-on:click="$router.push('/dashboard')"
          >
            <img src="/img/dashboard.png" />
            <span class="menu-title">Dashboard</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Manage Banners')">
          <a
            id="landingPage"
            :class="{ active: pageName == 'banner' }"
            style="cursor: pointer"
            v-on:click="$router.push('/banner')"
          >
            <img src="/images/landing.png" />
            <span class="menu-title">Manage Banners</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Statistics')">
          <a
            id="statistics"
            :class="{ active: pageName == 'statistics' }"
            style="cursor: pointer"
            v-on:click="$router.push('/statistics')"
          >
            <i class="fa fa-bar-chart" aria-hidden="true"></i>
            <span class="menu-title">Statistics</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Notifications')">
          <a
            id="manageNotification"
            :class="{ active: pageName.includes('manage') }"
            style="cursor: pointer"
            v-on:click="$router.push('/manage-notification')"
          >
            <i class="fa fa-bell" aria-hidden="true"></i>
            <span class="menu-title">Notifications</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Manage Customers')">
          <a
            id="customer"
            :class="{
              active:
                pageName.includes('customer') ||
                pageName.includes('post-review') ||
                pageName.includes('like') ||
                pageName.includes('shared') ||
                pageName.includes('activity-log'),
            }"
            style="cursor: pointer"
            v-on:click="$router.push('/customer')"
          >
            <i class="fa fa-users" aria-hidden="true"></i>
            <span class="menu-title">Manage Customers</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Manage Products')">
          <a
            id="product"
            :class="{ active: pageName == 'product' }"
            style="cursor: pointer"
            class=""
            v-on:click="$router.push('/product')"
          >
            <i class="fa fa-shopping-cart" aria-hidden="true"></i>
            <span class="menu-title">Manage Products </span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Requested Products')">
          <a
            id="category"
            :class="{
              active: pageName.includes('requested') || pageName.includes('accept'),
            }"
            style="cursor: pointer"
            v-on:click="$router.push('/requested')"
          >
            <i class="fa fa-hand-o-up" aria-hidden="true"></i>
            <span class="menu-title">Requested Products</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Manage Products')">
          <a
            id="product_attribute"
            :class="{ active: pageName == 'product_attribute' }"
            style="cursor: pointer"
            class=""
            v-on:click="$router.push('/product_attribute')"
          >
            <i class="fa fa-shopping-cart" aria-hidden="true"></i>
            <span class="menu-title">Product Attributes</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Manage Categories')">
          <a
            id="category"
            :class="{ active: pageName.includes('category') }"
            style="cursor: pointer"
            v-on:click="$router.push('/category')"
          >
            <i class="fa fa-sitemap" aria-hidden="true"></i>
            <span class="menu-title">Manage Categories</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Manage Brands')">
          <a
            id="brand"
            :class="{ active: pageName == 'brand' }"
            style="cursor: pointer"
            v-on:click="$router.push('/brand')"
          >
            <i class="fa fa-tags" aria-hidden="true"></i>
            <span class="menu-title">Manage Brands</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Manage Post')">
          <a
            id="post"
            :class="{ active: pageName == 'post' }"
            style="cursor: pointer"
            v-on:click="$router.push('/posts')"
          >
            <i class="fa fa-tags" aria-hidden="true"></i>
            <span class="menu-title">Manage Posts</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Manage Ingredient')">
          <a
            id="ingredient"
            :class="{ active: pageName == 'ingredient' }"
            style="cursor: pointer"
            v-on:click="$router.push('/ingredient')"
          >
            <i class="fa fa-tags" aria-hidden="true"></i>
            <span class="menu-title">Manage Ingredient</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Manage Language')">
          <a
            id="language"
            :class="{ active: pageName == 'language' }"
            style="cursor: pointer"
            v-on:click="$router.push('/language')"
          >
            <i class="fa fa-tags" aria-hidden="true"></i>
            <span class="menu-title">Manage Language</span>
          </a>
        </li>

        <li v-show="$helperService.getLeftMenuAccess('Manage Language')">
          <a
            id="meta-content"
            :class="{ active: pageName == 'meta-content' }"
            style="cursor: pointer"
            v-on:click="$router.push('/meta-content')"
          >
            <i class="fa fa-tags" aria-hidden="true"></i>
            <span class="menu-title">Manage Meta Content</span>
          </a>
        </li>

        <li v-show="$helperService.getLeftMenuAccess('Manage Currency')">
          <a
            id="currency"
            :class="{ active: pageName.includes('currency') }"
            style="cursor: pointer"
            v-on:click="$router.push('/currency')"
          >
            <i class="fa fa-user" aria-hidden="true"></i>
            <span class="menu-title">Manage Currency</span>
          </a>
        </li>

        <li v-show="$helperService.getLeftMenuAccess('Manage Contents')">
          <a
            id="contents"
            :class="{ active: pageName.includes('contents') }"
            style="cursor: pointer"
            v-on:click="$router.push('/contents')"
          >
            <i class="fa fa-align-center" aria-hidden="true"></i>
            <span class="menu-title">Manage Contents</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Manage Admins')">
          <a
            id="admin"
            :class="{ active: pageName.includes('admin') }"
            style="cursor: pointer"
            v-on:click="$router.push('/admin')"
          >
            <i class="fa fa-user" aria-hidden="true"></i>
            <span class="menu-title">Manage Admins</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Manage Roles')">
          <a
            id="role"
            :class="{ active: pageName.includes('role') }"
            style="cursor: pointer"
            v-on:click="$router.push('/role')"
          >
            <!--<i class="fa fa-address-card" aria-hidden="true"></i>-->
            <i class="fa fa-tasks" aria-hidden="true"></i>
            <span class="menu-title">Manage Roles</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Send Notifications')">
          <a
            id="notification"
            :class="{
              active:
                pageName.includes('snd') ||
                pageName.includes('send') ||
                pageName.includes('history'),
            }"
            style="cursor: pointer"
            v-on:click="$router.push('/snd-notification')"
          >
            <i class="fa fa-bell" aria-hidden="true"></i>
            <span class="menu-title">Send Notifications</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Manage Newsletter')">
          <a
            id="newsletter"
            :class="{ active: pageName == 'newsletter' }"
            style="cursor: pointer"
            v-on:click="$router.push('/newsletter')"
          >
            <i class="fa fa-tags" aria-hidden="true"></i>
            <span class="menu-title">Manage Newsletter</span>
          </a>
        </li>
        <li v-show="$helperService.getLeftMenuAccess('Manage Reviews')">
          <a
            id="role"
            :class="{ active: pageName.includes('all') }"
            style="cursor: pointer"
            v-on:click="$router.push('/all-review')"
          >
            <!--<i class="fa fa-address-card" aria-hidden="true"></i>-->
            <i class="fa fa-tasks" aria-hidden="true"></i>
            <span class="menu-title">Manage Reviews</span>
          </a>
        </li>
      </ul>
    </div>
    <header class="top-header">
      <nav class="navbar navbar-expand">
        <div class="left-topbar d-flex align-items-center">
          <a href="javascript:;" class="toggle-btn">
            <i class="bx bx-menu"></i>
          </a>
        </div>
        <div class="right-topbar ml-auto">
          <ul class="navbar-nav">
            <li class="nav-item dropdown dropdown-user-profile">
              <a
                class="nav-link dropdown-toggle dropdown-toggle-nocaret pr-0"
                href="javascript:;"
                data-toggle="dropdown"
              >
                <div class="media user-box align-items-center">
                  <div class="media-body user-info">
                    <p class="user-name mb-0" id="loginuser-name">
                      {{ details.name }}
                    </p>
                  </div>
                  <img
                    :src="details.image ? details.image : '/img/user-pic.png'"
                    class="user-img"
                    alt="user avatar"
                    id="loginuser-image"
                  />
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <router-link to="/my-profile">
                  <a class="dropdown-item">
                    <i class="bx bx-user"></i>
                    <span>Profile</span>
                  </a>
                </router-link>
                <div class="dropdown-divider mb-0"></div>
                <a class="dropdown-item" style="cursor: pointer" v-on:click="logout">
                  <i class="bx bx-power-off"></i>
                  <span>Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>
<script>
import api from "../services/WebService";
export default {
  name: "MenuPage",
  data() {
    return {
      pageName: "",
      details: {},
      isShow: false,
    };
  },
  watch: {
    "$route.fullPath"(newValue) {
      var pathname = newValue.replace("/", "");
      this.pageName = pathname;
    },
  },
  mounted() {
    this.pageName = this.$route.fullPath.replace("/", "");
    this.getLoginUserDetail();
  },
  methods: {
    isShowList() {
      this.isShow = !this.isShow;
    },
    logout() {
      api
        .postAPI({
          _action: "/unregister/device/token",
        })
        .then(() => {
          localStorage.clear();
          this.$router.push("/login");
        })
        .catch(() => {});
    },
    getLoginUserDetail() {
      api
        .getAPI({
          _action: "/users/me",
        })
        .then((res) => {
          // if (res.role != "ADMIN") {
          //   this.$notify({
          //     type: "success",
          //     text: "You don't have access ",
          //   });
          // }

          if (window.location.pathname == "/access-denied") {
            this.$router.go(-1);
          }
          if (res.access && res.access.length > 0) {
            this.$helperService.setAccessLevel(res.access);
          }

          this.details = res;
        })
        .catch(() => {});
    },
  },
};
const accSingleTriggers = document.querySelectorAll(".js-acc-single-trigger");
accSingleTriggers.forEach((trigger) =>
  trigger.addEventListener("click", toggleAccordion)
);

function toggleAccordion() {
  const items = document.querySelectorAll(".js-acc-item");
  const thisItem = this.parentNode;
  items.forEach((item) => {
    if (thisItem == item) {
      thisItem.classList.toggle("is-open");
      return;
    }
    item.classList.remove("is-open");
  });
}
</script>
